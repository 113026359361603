import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';

import './settings.css';
import theme1 from '../../assets/theme-1.jpg';
import theme1dark from '../../assets/theme-1-dark.jpg';

const Settings = () => {
    const isMounted = useRef(false);

    const navigate = useNavigate();

    const [showSecurityPanel, setShowSecurityPanel] = useState(true);
    const [showAppearancePanel, setShowAppearancePanel] = useState(false);

    const formSecurityQuestionErrorRef = useRef();
    const formSecurityQuestionSuccessRef = useRef();
    const formThemeErrorRef = useRef();

    const [showSecurityQuestionForm, setShowSecurityQuestionForm] = useState(false);
    
    const [showAnswer, setShowAnswer] = useState(false);
    const [currentPword3, setCurrentPword3] = useState('');
    const [securityQuestion, setSecurityQuestion] = useState('');
    const [securityAnswer, setSecurityAnswer] = useState('');
    const [formSecurityQuestionErrorMsg, setFormSecurityQuestionErrorMsg] = useState('');
    const [formSecurityQuestionSuccessMsg, setFormSecurityQuestionSuccessMsg] = useState('');
    const [formSecurityQuestionError, setFormSecurityQuestionError] = useState(false);
    const [formSecurityQuestionSuccess, setFormSecurityQuestionSuccess] = useState(false);
    const [formSubmitSecurityQuestion, setFormSubmitSecurityQuestion] = useState(false);

    const [squestion, setSQuestion] = useState('');
    const accessToken = sessionStorage.getItem('accessToken');

    const theme = sessionStorage.getItem('theme');
    const [selectedTheme, setSelectedTheme] = useState(theme);
    const [submitThemeForm, setSubmitThemeForm] = useState(false);
    const [formThemeError, setFormThemeError] = useState(false);
    const [formThemeErrorMsg, setFormThemeErrorMsg] = useState('');

    useEffect(() => {
        isMounted.current = true;

        const fetchSecurityQuestion = async () => {
            try {
    
                const meta = await axios.post('getusermeta',
                    JSON.stringify({'mk': 'security_question'}),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
    
                if(isMounted.current && meta.data.status === 'success') setSQuestion(meta?.data.data);
    
            } catch(err) {
    
            }
        }

        fetchSecurityQuestion();
        setShowAnswer(false);
        setShowSecurityPanel(true);
        setShowAppearancePanel(false);
        setSubmitThemeForm(false);

        return () => { isMounted.current = false };
        
    }, [accessToken]);

    useEffect(() => {
        setFormSecurityQuestionErrorMsg('');
        setFormSecurityQuestionSuccessMsg('');
        setFormSecurityQuestionError(false);
        setFormSecurityQuestionSuccess(false);
    }, [currentPword3, securityQuestion, securityAnswer]);

    useEffect(() => {
        setFormThemeErrorMsg('');
        setFormThemeError(false);
    }, [selectedTheme]);

    const fetchUserMeta = async () => {
        try {

            const meta = await axios.post('getusermeta',
                JSON.stringify({'mk': 'security_question'}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken
                    }
                }
            );

            if(isMounted.current && meta.data.status === 'success') setSQuestion(meta?.data.data);

        } catch(err) {

        }
    }

    const handleShowAnswer = (e) => {
        e.preventDefault();
        if (showAnswer) {
            setShowAnswer(false);
        } else {
            setShowAnswer(true);
        }
    }

    const handleShowSecurityQuestionForm = (e) => {
        e.preventDefault();
        setShowSecurityQuestionForm(true);
    }

    const handleHideSecurityQuestionForm = (e) => {
        e.preventDefault();
        setCurrentPword3('');
        setSecurityQuestion('');
        setSecurityAnswer('');
        setFormSecurityQuestionSuccessMsg('');
        setFormSecurityQuestionSuccess(false);
        setFormSubmitSecurityQuestion(false);
        setShowSecurityQuestionForm(false);
    }

    const handleSecurityQuestion = async (e) => {
        e.preventDefault();
        setShowAnswer(false);
        setFormSubmitSecurityQuestion(true);

        try {

            const responseSQ = await axios.post('savesqa',
                JSON.stringify({
                    'pword': currentPword3,
                    'sq': securityQuestion,
                    'sa': securityAnswer
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken
                    }
                }
            );

            if(responseSQ.data.status === 'success') {
                setFormSecurityQuestionSuccessMsg(responseSQ?.data.msg);
                setFormSecurityQuestionSuccess(true);
                formSecurityQuestionSuccessRef.current.focus();

                setTimeout(() => {
                    fetchUserMeta();
                    setCurrentPword3('');
                    setSecurityQuestion('');
                    setSecurityAnswer('');
                    setFormSecurityQuestionSuccessMsg('');
                    setFormSecurityQuestionSuccess(false);
                    setFormSubmitSecurityQuestion(false);
                    setShowSecurityQuestionForm(false);
                }, 3000);
            }

        } catch(err) {
            if (!err.response) {
                setFormSecurityQuestionErrorMsg('No server response');
            } else {
                setFormSecurityQuestionErrorMsg(err.response?.data.msg);
            }

            formSecurityQuestionErrorRef.current.focus();
            setFormSecurityQuestionError(true);
            setFormSubmitSecurityQuestion(false);
        }
    }

    const handleSecurityPanel = (e) => {
        e.preventDefault();
        setShowSecurityPanel(true);
        setShowAppearancePanel(false);
    }

    const handleAppearancePanel = (e) => {
        e.preventDefault();
        setShowAppearancePanel(true);
        setShowSecurityPanel(false);
        setShowSecurityQuestionForm(false);
    }

    const handleSubmitThemeForm = async (e) => {
        e.preventDefault();
        setSubmitThemeForm(true);

        try {

            const saveTheme = await axios.post('savesetting',
                JSON.stringify({ sk: 'camp_theme', sv: selectedTheme }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken
                    }
                }
            );

            if (saveTheme.data.status === 'success') {
                sessionStorage.setItem('theme', selectedTheme);
                setSubmitThemeForm(false);
                navigate('/cVXsR/settings');
            }

        } catch (err) {
            if (!err.response) {
                setFormThemeErrorMsg('No server response.');
            } else {
                setFormThemeErrorMsg(err.response?.data.msg);
            }
            formThemeErrorRef.current.focus();
            setFormThemeError(true);
            setSubmitThemeForm(false);
        }
    }

    return (
        <div className='rty__dashboard-settings'>
            <div className='rty__dashboard-settings_header'>
                <h2>Settings</h2>
                <Link to='/cVXsR/dashboard'>
                    <button typeof='button'>
                        <i className='fa fa-times'></i>
                    </button>
                </Link>
            </div>

            <div className='rty__dashboard-settings_body'>
                <div className='rty__dashboard-settings_body-sidebar'>
                    <button 
                        type='button' 
                        className={showSecurityPanel ? 'active' : ''}
                        onClick={handleSecurityPanel}
                    >SECURITY</button>
                    <button 
                        type='button'
                        className={showAppearancePanel ? 'active' : ''}
                        onClick={handleAppearancePanel}
                    >APPEARANCE</button>
                </div>

                <div className='rty__dashboard-settings_content'>
                    {/* security wrapper */}
                    <div className={`rty__dashboard-settings_content-security ${showSecurityPanel ? 'show' : ''}`}>
                        <div className='rty__dashboard-settings_body-panel'>
                            <div className='rty__dashboard-settings_body-panel-header'>
                                <h3>
                                    <i className='fa fa-comment'></i>
                                    Security Question
                                </h3>
                                <button type='button' onClick={handleShowSecurityQuestionForm}>EDIT</button>
                            </div>
                            <div className='rty__dashboard-settings-body-panel-content'>
                                <p>Select a security question and input your answer so you can reset your password in case you have trouble signing in to your CAMP account.</p>

                                <div>
                                    <p>Security Question:</p>
                                    <h3>{squestion}</h3>
                                </div>

                                <form className={showSecurityQuestionForm ? 'rty__dashboard-settings-form_security_question show' : 'rty__dashboard-settings-form_security_question'} onSubmit={handleSecurityQuestion}>
                                    <p ref={formSecurityQuestionErrorRef} className={formSecurityQuestionError ? 'rty__dashboard-settings-form_security_question-error show' : 'rty__dashboard-settings-form_security_question-error'}>
                                        <i className='fa fa-exclamation-triangle'></i>
                                        <span>{formSecurityQuestionErrorMsg}</span>
                                    </p>

                                    <p ref={formSecurityQuestionSuccessRef} className={formSecurityQuestionSuccess ? 'rty__dashboard-settings-form_security_question-success show' : 'rty__dashboard-settings-form_security_question-success'}>
                                        <i className='fa fa-check-circle'></i>
                                        <span>{formSecurityQuestionSuccessMsg}</span>
                                    </p>

                                    <div>
                                        <label>Current Password</label>
                                        <input
                                            type='password'
                                            id='currentPword3'
                                            onChange={(e) => {setCurrentPword3(e.target.value)}}
                                            value={currentPword3}
                                            disabled={formSubmitSecurityQuestion ? true : false} 
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label>Security Question:</label>
                                        <select 
                                            id="securityQuestion" 
                                            onChange={(e) => setSecurityQuestion(e.target.value)}
                                            value={securityQuestion} 
                                            disabled={formSubmitSecurityQuestion ? true : false} 
                                            required
                                        >
                                            <option value=""></option>
                                            <option value="What is the food you least liked as a child?">What is the food you least liked as a child?</option>
                                            <option value="What is the name of your first stuffed animal?">What is the name of your first stuffed animal?</option>
                                            <option value="What did you earn your first medal or award for?">What did you earn your first medal or award for?</option>
                                            <option value="What is your favorite security question?">What is your favorite security question?</option>
                                            <option value="What is the toy/stuffed animal you liked the most as a kid?">What is the toy/stuffed animal you liked the most as a kid?</option>
                                            <option value="What was the first computer game you played?">What was the first computer game you played?</option>
                                            <option value="What is your favorite movie quote?">What is your favorite movie quote?</option>
                                            <option value="What was the mascot of the first sports team you played on?">What was the mascot of the first sports team you played on?</option>
                                            <option value="What music album or song did you first purchase?">What music album or song did you first purchase?</option>
                                            <option value="What is your favorite piece of art?">What is your favorite piece of art?</option>
                                            <option value="What was your grandmother`s favorite dessert?">What was your grandmother's favorite dessert?</option>
                                            <option value="What was the first thing you learned to cook?">What was the first thing you learned to cook?</option>
                                            <option value="What was your dream job as a child?">What was your dream job as a child?</option>
                                            <option value="Where did you meet your spouse/significant other?">Where did you meet your spouse/significant other?</option>
                                            <option value="Where did you go for your favorite vacation?">Where did you go for your favorite vacation?</option>
                                            <option value="Where were you on New Year`s Eve in the year 2000?">Where were you on New Year's Eve in the year 2000?</option>
                                            <option value="Who is your favorite speaker/orator?">Who is your favorite speaker/orator?</option>
                                            <option value="Who is your favorite book/movie character?">Who is your favorite book/movie character?</option>
                                            <option value="Who is your favorite sports player?">Who is your favorite sports player?</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label>Answer</label>
                                        <div className='rty__dashboard-settings-form_input-with_button'>
                                            <input 
                                                type={(showAnswer) ? 'text' : 'password'} 
                                                id='securityAnswer' 
                                                onChange={(e) => {setSecurityAnswer(e.target.value)}}
                                                value={securityAnswer}
                                                disabled={formSubmitSecurityQuestion ? true : false} 
                                                required 
                                            />
                                            <button 
                                                className='rty__dashboard-settings-form_input-change-view' 
                                                type='button' 
                                                onClick={handleShowAnswer}
                                            >
                                                <i className={(showAnswer) ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='btn-group'>
                                        <button 
                                            type='submit' 
                                            disabled={formSubmitSecurityQuestion ? true : false}
                                        >
                                            Submit 
                                            <>
                                                {
                                                    formSubmitSecurityQuestion ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                                }
                                            </>
                                        </button>
                                        <button 
                                            type='reset' 
                                            disabled={formSubmitSecurityQuestion ? true : false}
                                            onClick={handleHideSecurityQuestionForm}
                                        >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    {/* appearance wrapper */}
                    <div className={`rty__dashboard-settings_content-appearance ${showAppearancePanel ? 'show' : ''}`}>
                        <div className='rty__dashboard-settings_body-panel'>
                            <div className='rty__dashboard-settings_body-panel-header'>
                                <h3>
                                    <i className='fa fa-lock'></i>
                                    Theme
                                </h3>
                                <button 
                                    type='button'
                                    onClick={handleSubmitThemeForm}
                                    disabled={submitThemeForm}
                                >
                                    SAVE 
                                    <>{
                                        submitThemeForm
                                            ? (<i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>)
                                            : ('')
                                    }</>
                                </button>
                            </div>
                            <div className='rty__dashboard-settings-body-panel-content'>
                                <p ref={formThemeErrorRef} className={`rty__dashboard-settings-form_theme-error ${formThemeError ? 'show' : ''}`}>
                                    <i className='fa fa-exclamation-triangle'></i>
                                    <span>{formThemeErrorMsg}</span>
                                </p>

                                <form>
                                    <label>
                                        <img src={theme1} alt='Theme 1' />
                                        <input 
                                            type='radio' 
                                            name='theme' 
                                            onChange={(e) => setSelectedTheme(e.target.value)}
                                            value='theme-1' 
                                            checked={theme === 'null' || theme === '' || theme === 'theme-1' || selectedTheme === 'theme-1' ? true : false}
                                            disabled={submitThemeForm} 
                                        />
                                    </label>
                                    <label>
                                        <img src={theme1dark} alt='Theme 1 Dark' />
                                        <input 
                                            type='radio' 
                                            name='theme' 
                                            onChange={(e) => setSelectedTheme(e.target.value)}
                                            value='theme-1-dark' 
                                            checked={theme === 'theme-1-dark' || selectedTheme === 'theme-1-dark' ? true : false}
                                            disabled={submitThemeForm} 
                                        />
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;
