import React from 'react';
import { Link, useNavigate} from 'react-router-dom';
import axios from '../../api/axios';

import './topbar.css';
import logo from '../../assets/msw-logo.png'
import { useDispatch } from 'react-redux';
import { addSites, addHelpdesk } from '../../redux/slices/DataSlice';

const Topbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const img = sessionStorage.getItem('img');
    const name = sessionStorage.getItem('name');
    const accessToken = sessionStorage.getItem('accessToken');
    
    const handleLogout = () => {
        axios.post('destroycampsession', 
            JSON.stringify({}), 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'X-Aim-Token': accessToken
                }
            }
        );

        sessionStorage.setItem('uname', '');
        sessionStorage.setItem('name', '');
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('emp_id', '');
        sessionStorage.setItem('img', '');
        sessionStorage.setItem('department', '');
        sessionStorage.setItem('department_code', '');
        sessionStorage.setItem('outlet', '');
        sessionStorage.setItem('outlet_code', '');
        // sessionStorage.setItem('pwdexp', '');
        sessionStorage.setItem('theme', '');
        sessionStorage.setItem('accessToken', '');
        sessionStorage.clear();

        dispatch(addSites([]));
        dispatch(addHelpdesk([]));
            
        navigate('/');
    }

    return (
        <div className='rty__dashboard-topbar'>
            <div className='rty__dashboard-topbar_logo'>
                <img src={logo} alt='logo' />
                <div className='rty__dashboard-topbar_logo-title'>
                    <p>Centralized <span>Access</span></p>
                    <p>Management <span>Program</span></p>
                </div>
            </div>

            <div className='rty__dashboard-topbar_details'>
                <p className='rty__dashboard-topbar_details-name'>{name}</p>
                <div 
                    className='rty__dashboard-topbar_details-avatar' 
                    style={{backgroundImage: `url(${img})`}}>
                </div>
                <div className='rty__dashboard-topbar_details-divider' />
                <Link to='/cVXsR/settings'>
                    <button type='button' title='Settings'>
                        <i className='fa fa-cog'></i>
                    </button>
                </Link>
                <button type='button' title='Logout' onClick={handleLogout}>
                    <i className='fa fa-power-off'></i>
                </button>
            </div>
        </div>
    )
}

export default Topbar;
