import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';

import './dashboard.css';
import Topbar from '../../components/topbar/Topbar';
import Tms from '../../components/tms/Tms';
import helpdeskLogo from '../../assets/it-helpdesk.gif';

const Dashboard = () => {
    const navigate = useNavigate();
    
    const isMounted = useRef(false);

    const [itpdhelpdesk, setITPHelpdesk] = useState(false);
    const [tmsaccess, setTMSAccess] = useState(false);

    const email = sessionStorage.getItem('email');
    const emp_id = sessionStorage.getItem('emp_id');
    const currentTheme = sessionStorage.getItem('theme');
    const theme = (currentTheme === 'theme-1-dark') ? 'dark' : '';
    const accessToken = sessionStorage.getItem('accessToken');

    useEffect(() => {
        isMounted.current = true;

        const checkCAMPSession = async () => {
    
            try {
                const checkSession = await axios.post('checkcampsession', 
                    JSON.stringify({}), 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
                
                if (isMounted.current && checkSession.data.status !== 'active') destroyCurrentSession();
    
            } catch (err) {
    
                destroyCurrentSession();
    
            }
    
        }
    
        const checkAccessTokenValidity = async () => {
    
            try {
                const checkAccessToken = await axios.get(
                    'checkaccesstoken',
                    {
                        headers: {
                            'Content-Type': 'application.json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
        
                if (isMounted.current && checkAccessToken.data !== 'active') destroyCurrentSession();
    
            } catch (err) {
                
                destroyCurrentSession();
    
            }
    
        }

        const destroyCurrentSession = () => {
    
            axios.post('destroycampsession', 
                JSON.stringify({}),  
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken
                    }
                }
            );
    
            sessionStorage.setItem('uname', '');
            sessionStorage.setItem('name', '');
            sessionStorage.setItem('email', '');
            sessionStorage.setItem('emp_id', '');
            sessionStorage.setItem('img', '');
            sessionStorage.setItem('department', '');
            sessionStorage.setItem('department_code', '');
            sessionStorage.setItem('outlet', '');
            sessionStorage.setItem('outlet_code', '');
            // sessionStorage.setItem('pwdexp', '');
            sessionStorage.setItem('theme', '');
            sessionStorage.setItem('accessToken', '');
            sessionStorage.clear();
                
            navigate('/');
    
        }

        const checkITPDHelpdeskAccess = async () => {
            
            try {
                const checkHelpdeskAccess = await axios.post('checksiteaccess', 
                    JSON.stringify({
                        'sitekey': process.env.REACT_APP_ITPD_HELPDESK_TOKEN
                    }), 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
                
                if (isMounted.current && checkHelpdeskAccess.data.status === 'success') setITPHelpdesk(true);
    
            } catch (err) {
                
                setITPHelpdesk(false);
    
            }
    
        }

        const checkUserTMSAccess = async () => {
            
            try {
                const checkTMSAccess = await axios.post('checksiteaccess', 
                    JSON.stringify({
                        'sitekey': process.env.REACT_APP_TMS_TOKEN
                    }), 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
                
                if (isMounted.current && checkTMSAccess.data.status === 'success') setTMSAccess(true);
    
            } catch (err) {
    
                setTMSAccess(false);
    
            }
    
        }

        checkCAMPSession();
        checkAccessTokenValidity();
        checkITPDHelpdeskAccess();
        checkUserTMSAccess();
        
        return () => { isMounted.current = false };

    }, [accessToken, navigate]);

    return (
        <div className={`rty__dashboard ${theme}`}>

            <div className='rty__dashboard-content'>
                <Topbar />
                <div className='rty__dashboard-content_body'>
                    <div className='rty__dashboard-content_body-sidebar-left'>
                        <div className='rty__dashboard-content_body-sidebar-left_details'>
                            <p>
                                <i className='fa fa-envelope'></i>
                                {email}
                            </p>
                            <p>
                                <i className='fa fa-id-card'></i>
                                {emp_id}
                            </p>
                        </div>
                    </div>

                    <div className='rty__dashboard-content_body-container'>
                        <Outlet />
                    </div>
                    
                    <>
                        {
                            (itpdhelpdesk || tmsaccess) 
                                ? (<div className='rty__dashboard-content_body-sidebar-right'>

                                    <>
                                        {
                                            (itpdhelpdesk)
                                                ? (<div className='rty__dashboard-content_body-sidebar-right_helpdesk'>
                                                    <img src={helpdeskLogo} alt="Helpdesk Logo" />
                                                    <Link to='/cVXsR/itpd-helpdesk'>
                                                        <button type='button'>ITPD Helpdesk</button>
                                                    </Link>
                                                </div>)
                                                : ('')
                                        }
                                    </>
                                    

                                    <>
                                        {
                                            (tmsaccess)
                                                ? (<Tms />)
                                                : ('')
                                        }
                                    </>
                                    
                                </div>)
                                : ('')
                        }
                    </>
                    

                </div>
            </div>
            <div className='rty__dashboard-footer'>
                <p>&copy; Megasportsworld 2020.</p>
            </div>
        </div>
    )
}

export default Dashboard;
