import React from 'react';
import './loader.css'

const Loader = () => {
    return (
        <div className='rty__loader'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    )
}

export default Loader;
