import React, { useEffect, useRef, useState } from 'react';
import './forgot-password.css';

import logo from '../../assets/msw-logo-white.png'
import axios from '../../api/axios';

const ForgotPassword = () => {
    const errRef = useRef();
    const sucRef = useRef();

    const [email, setEmail] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [sucMsg, setSucMsg] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);

    useEffect(() => {
        setErrMsg('');
        setSucMsg('');
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmit(true);

        try {

            const response = await axios.post('forgotpassword',
                JSON.stringify({email}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN
                    }
                }
            );

            setSucMsg(response.data.msg);
            sucRef.current.focus();

            setFormSubmit(false);

        } catch(err) {
            if(!err?.response) {
                setErrMsg('No server response!');
            } else {
                setErrMsg(err.response?.data.msg);
            }
            errRef.current.focus();
            setFormSubmit(false);
        }
    }

    return (
        <div className='rty__forgot-password'>
            <div className='rty__forgot-password-container'>
                <form id='frmForgotPassword' onSubmit={handleSubmit}>

                    <div ref={errRef} className={errMsg ? "rty__forgot-password-message_error show" : "rty__forgot-password-message_error"} aria-live='assertive'>
                        <p><i className="fa fa-exclamation-triangle"></i> <span>{errMsg}</span></p>
                    </div>

                    <div ref={sucRef} className={sucMsg ? "rty__forgot-password-message_success show" : "rty__forgot-password-message_success"} aria-live='assertive'>
                        <p><i className="fa fa-check-circle"></i> <span>{sucMsg}</span></p>
                    </div>


                    <div className='rty__forgot-password-form_header'>
                        {/* content before waves */}
                        <div className='rty__forgot-password-form_header-logo-container'>
                            <div className='rty__forgot-password-form_header-logo'>
                                <img src={logo} alt='logo' />
                            </div>
                        </div>

                        {/* waves container */}
                        <div className='rty__forgot-password-form_header-waves'>
                            <svg className='waves' xmlns='//www.w3.org/2000/svg' xmlnsXlink='//www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                <defs>
                                    <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                </defs>
                                <g className='parallax'>
                                    <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                    <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div className='rty__forgot-password-form_body'>
                        <input 
                            type='email' 
                            id='email' 
                            onChange={(e) => setEmail(e.target.value)} 
                            value={email} 
                            placeholder='Email Address' 
                            autoComplete='off' 
                            required  
                            disabled={formSubmit} 
                        />
                        <button disabled={formSubmit}>
                            SUBMIT 
                            <>
                                {
                                    formSubmit ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                }
                            </>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;
