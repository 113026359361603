import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import App from './App';
import { store } from './redux/store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
          <Router>
              <AuthProvider>
                  <Routes>
                      <Route path='/*' element={<App />} />
                  </Routes>
              </AuthProvider>
          </Router>
        </Provider>
    </React.StrictMode>
);