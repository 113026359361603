import { createSlice } from "@reduxjs/toolkit";

const DataSlice = createSlice({
    name: 'data',
    initialState: {
        sites: [],
        helpdesk: []
    },
    reducers: {
        addSites(state, action) {
            state.sites = action.payload
        },
        addHelpdesk(state, action) {
            state.helpdesk = action.payload
        }
    }
});

export const { addSites, addHelpdesk } = DataSlice.actions;
export default DataSlice.reducer;