import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import axios from '../../api/axioscamp';

import './tms.css';

const Tms = ({format}) => {
	const toast = Swal.mixin({
	    toast: true,
	    position: 'top-end',
	    showConfirmButton: false,
	    timer: 5000,
	    timerProgressBar: true
	});

    const accessToken = sessionStorage.getItem('accessToken');
    const uname = sessionStorage.getItem('uname');
    const name = sessionStorage.getItem('name');
    const department = sessionStorage.getItem('department');
    const department_code = sessionStorage.getItem('department_code');
    const outlet = sessionStorage.getItem('outlet');
    const outlet_code = sessionStorage.getItem('outlet_code');


    // clock script
	const [time, setTime] = useState(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(new Date());
		});

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const hours = time.getHours();
	const minutes = time.getMinutes();
	const seconds = time.getSeconds();

	let formattedHours = hours;
	let ampm = "";

	if (format === "12") {
		formattedHours = hours % 12 || 12;
		ampm = hours >= 12 ? 'PM' : 'AM';
	}


	// button function
	const [btntimein, setBtnTimeIn] = useState(true);
	const [btntimeout, setBtnTimeOut] = useState(true);
	const [btnstartbreak, setBtnStartBreak] = useState(true);
	const [btnstopbreak, setBtnStopBreak] = useState(true);
	const [clicktimein, setClickTimeIn] = useState(false);
	const [clicktimeout, setClickTimeOut] = useState(false);
	const [clickstartbreak, setClickStartBreak] = useState(false);
	const [clickstopbreak, setClickStopBreak] = useState(false);
	const [clickBreakTimeModal, setClickBreakTimeModal] = useState('');
	const [showBreakTimeModal, setBreakTimeModal] = useState(false);
	const [btnbreak15m, setBtnBreak15m] = useState(true);
	const [btnbreak30m, setBtnBreak30m] = useState(true);
	const [btnbreak1h, setBtnBreak1h] = useState(true);
	const [click15minbreak, setClick15minBreak] = useState(false);
	const [click30minbreak, setClick30minBreak] = useState(false);
	const [click1hrbreak, setClick1hrBreak] = useState(false);

	const checkButtonsTMS = useCallback(async () => {
		try {
			const buttons = await axios.post(
				'tms/checkuserbuttons',
				JSON.stringify({uname}),
				{
					headers: {
						'Content-Type': 'application/json',
						'X-Aim-Token': accessToken
					}
				}
			);

			if (buttons.data.status === 'success') {
				(buttons.data.data.timein === 'disabled') ? setBtnTimeIn(true) : setBtnTimeIn(false);
				(buttons.data.data.timeout === 'disabled') ? setBtnTimeOut(true) : setBtnTimeOut(false);
				(buttons.data.data.breakin === 'disabled') ? setBtnStartBreak(true) : setBtnStartBreak(false);
				(buttons.data.data.breakout === 'disabled') ? setBtnStopBreak(true) : setBtnStopBreak(false);
			}
		} catch (err) {
			setBtnTimeIn(true);
			setBtnTimeOut(true);
			setBtnStartBreak(true);
			setBtnStopBreak(true);
		}
	}, [accessToken, uname]);


	useEffect(() => {
		checkButtonsTMS();
	}, [checkButtonsTMS]);


	useEffect(() => {
		// special key event
		const keyDownHandler = event => {
			if (event.key === 'F1') {
				event.preventDefault();
				if (!btntimein) handleLogTime('f1');
			} else if (event.key === 'F2') {
				event.preventDefault();
				if (!btnstartbreak) handleBreakTime('f2');
			} else if (event.key === 'F3') {
				event.preventDefault();
				if (!btnstopbreak) handleBreakTime('f3');
			} else if (event.key === 'F4') {
				event.preventDefault();
				if (!btntimeout) handleLogTime('f4');
			}
		}

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		}
	});


	const handleLogTime = (target) => {

		var lbl = '';
		if (target === 'f1') lbl = 'Time In';
		/*else if (target === 'f2') lbl = 'Start Break';
		else if (target === 'f3') lbl = 'End Break';*/
		else if (target === 'f4') lbl = 'Time Out';

		Swal.fire({
			text: `Are you sure you want to log your ${lbl}?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			showLoaderOnConfirm: true,
			preConfirm: function(result) {
				return new Promise(function(resolve) {
			        if (result) {
			        	
			        	setBtnTimeIn(true);
						setBtnTimeOut(true);
						setBtnStartBreak(true);
						setBtnStopBreak(true);

						if (target === 'f1') {
							setClickTimeIn(true);
							setClickTimeOut(false);
							setClickStartBreak(false);
							setClickStopBreak(false);
						} else if (target === 'f2') {
							setClickTimeIn(false);
							setClickTimeOut(false);
							setClickStartBreak(true);
							setClickStopBreak(false);
						} else if (target === 'f3') {
							setClickTimeIn(false);
							setClickTimeOut(false);
							setClickStartBreak(false);
							setClickStopBreak(true);
						} else if (target === 'f4') {
							setClickTimeIn(false);
							setClickTimeOut(true);
							setClickStartBreak(false);
							setClickStopBreak(false);
						}


						axios.post(
							'tms/logusertime',
							JSON.stringify(
								{
									target,
									uname,
									name,
									dept: department,
									dept_code: department_code,
									outlet: outlet,
									outlet_code: outlet_code
								}
							),
							{
								headers: {
									'Content-Type': 'application/json',
									'X-Aim-Token': accessToken
								}
							}
						).then((response) => {
							
							if (response.data.status === 'success') {
								toast.fire({
									icon: 'success',
									title: response.data.msg
								});
							} else {
								toast.fire({
									icon: 'error',
									title: 'Something went wrong!'
								});
							}

							setClickTimeIn(false);
							setClickTimeOut(false);
							setClickStartBreak(false);
							setClickStopBreak(false);
							checkButtonsTMS();
							
		          			resolve();
						})
						.catch((error) => {
							toast.fire({
								icon: 'error',
								title: 'Something went wrong!'
							});
						});

						setClickTimeIn(false);
						setClickTimeOut(false);
						setClickStartBreak(false);
						setClickStopBreak(false);
						checkButtonsTMS();

					}  
		      	});
			}
		});

	}


	const handleBreakTimeModal = async (target) => {
		if (target === 'f2') {
			setClickTimeIn(false);
			setClickTimeOut(false);
			setClickStartBreak(true);
			setClickStopBreak(false);
		} else if (target === 'f3') {
			setClickTimeIn(false);
			setClickTimeOut(false);
			setClickStartBreak(false);
			setClickStopBreak(true);
		}

		setClickBreakTimeModal(target);

		try {
			const buttons = await axios.post(
				'tms/checkuserbreakbuttons',
				JSON.stringify({uname, target}),
				{
					headers: {
						'Content-Type': 'application/json',
						'X-Aim-Token': accessToken
					}
				}
			);

			if (buttons.data.status === 'success') {
				(buttons.data.data.min15 === 'disabled') ? setBtnBreak15m(true) : setBtnBreak15m(false);
				(buttons.data.data.min30 === 'disabled') ? setBtnBreak30m(true) : setBtnBreak30m(false);
				(buttons.data.data.hr1 === 'disabled') ? setBtnBreak1h(true) : setBtnBreak1h(false);
			
				setClickTimeIn(false);
				setClickTimeOut(false);
				setClickStartBreak(false);
				setClickStopBreak(false);
				setBreakTimeModal(true);
			}
		} catch (err) {
			setBtnBreak15m(true);
			setBtnBreak30m(true);
			setBtnBreak1h(true);
		}
	}


	const handleCloseBreakTimeModal = () => {
		setClickBreakTimeModal('');

		setClick15minBreak(false);
		setClick30minBreak(false);
		setClick1hrBreak(false);

		setBtnBreak15m(true);
		setBtnBreak30m(true);
		setBtnBreak1h(true);

		setBreakTimeModal(false);
	}


	const handleBreakTime = async (target) => {

		var lbl = '';
		if (target === 'min15') lbl = '15 minutes';
		else if (target === 'min30') lbl = '30 minutes';
		else if (target === 'hr1') lbl = '1 hr';

		Swal.fire({
			text: `Are you sure you want to log your ${lbl} break?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			showLoaderOnConfirm: true,
			preConfirm: function(result) {
				return new Promise(function(resolve) {
			        if (result) {
			        	
			        	setBtnBreak15m(true);
			        	setBtnBreak30m(true);
			        	setBtnBreak1h(true);

			        	if (target === 'min15') {
			        		setClick15minBreak(true);
			        		setClick30minBreak(false);
			        		setClick1hrBreak(false);
			        	} else if (target === 'min30') {
			        		setClick15minBreak(false);
			        		setClick30minBreak(true);
			        		setClick1hrBreak(false);
			        	} else if (target === 'hr1') {
			        		setClick15minBreak(false);
			        		setClick30minBreak(false);
			        		setClick1hrBreak(true);
			        	}


						axios.post(
							'tms/loguserbreaktime',
							JSON.stringify(
								{
									target,
									break: clickBreakTimeModal,
									uname,
									name,
									dept: department,
									dept_code: department_code,
									outlet: outlet,
									outlet_code: outlet_code
								}
							),
							{
								headers: {
									'Content-Type': 'application/json',
									'X-Aim-Token': accessToken
								}
							}
						).then((response) => {
							
							if (response.data.status === 'success') {
								toast.fire({
									icon: 'success',
									title: response.data.msg
								});
							} else {
								toast.fire({
									icon: 'error',
									title: response.data.msg
								});
							}


							setClick15minBreak(false);
			        		setClick30minBreak(false);
			        		setClick1hrBreak(false);

			        		setBtnBreak15m(true);
							setBtnBreak30m(true);
							setBtnBreak1h(true);

							setBreakTimeModal(false);
							checkButtonsTMS();
							
		          			resolve();
						})
						.catch((error) => {
							toast.fire({
								icon: 'error',
								title: 'Something went wrong!'
							});
						});


						setClick15minBreak(false);
		        		setClick30minBreak(false);
		        		setClick1hrBreak(false);

		        		setBtnBreak15m(true);
						setBtnBreak30m(true);
						setBtnBreak1h(true);

						setBreakTimeModal(false);
						checkButtonsTMS();
					}  
		      	});
			}
		});

	}


	return (
		<div className='rty__dashboard-content_body-sidebar-right_tms'>
			<div className="rty__dashboard-content_body-sidebar-right_tms-clock">
				{formattedHours}:
				{minutes < 10 ? `0${minutes}` : minutes}:
				{seconds < 10 ? `0${seconds}` : seconds} {ampm}
			</div>

	        <div className='rty__dashboard-content_body-sidebar-right_tms-buttons'>
	            <div>
	                <button type='button' onClick={() => handleLogTime('f1')} disabled={btntimein}>
	                    Time In (F1) 
	                    <>
	                    	{
	                    		(clicktimein)
	                    			? (<i className='fa fa-spinner fa-spin'></i>)
	                    			: ('')
	                    	}
	                    </>
	                </button>
	                {/*<button type='button' onClick={() => handleLogTime('f3')} disabled={btnstopbreak}>*/}
	                <button type='button' onClick={() => handleBreakTimeModal('f3')} disabled={btnstopbreak}>
	                    End Break (F3) 
	                    <>
	                    	{
	                    		(clickstopbreak)
	                    			? (<i className='fa fa-spinner fa-spin'></i>)
	                    			: ('')
	                    	}
	                    </>
	                </button>
	            </div>
	            
	            <div>
	                {/*<button type='button' onClick={() => handleLogTime('f2')} disabled={btnstartbreak}>*/}
	                <button type='button' onClick={() => handleBreakTimeModal('f2')} disabled={btnstartbreak}>
	                    Start Break (F2) 
	                    <>
	                    	{
	                    		(clickstartbreak)
	                    			? (<i className='fa fa-spinner fa-spin'></i>)
	                    			: ('')
	                    	}
	                    </>
	                </button>
	                <button type='button' onClick={() => handleLogTime('f4')} disabled={btntimeout}>
	                    Time Out (F4) 
	                    <>
	                    	{
	                    		(clicktimeout)
	                    			? (<i className='fa fa-spinner fa-spin'></i>)
	                    			: ('')
	                    	}
	                    </>
	                </button>
	            </div>
	        </div>


	        <>
            	{
            		(showBreakTimeModal)
            			? (<div className='rty__dashboard-content_body-sidebar-right_tms-break_modal'>
					        	<div className='rty__dashboard-content_body-sidebar-right_tms-break_modal-inner'>
					        		<button type='button' className='close_modal' onClick={() => handleCloseBreakTimeModal()}>
					        			<i className='fa fa-times'></i>
					        		</button>

					        		<button type='button' onClick={() => handleBreakTime('min15')} disabled={btnbreak15m}>
				        				15<br />minutes 
					                    <>
					                    	{
					                    		(click15minbreak)
					                    			? (<i className='fa fa-spinner fa-spin'></i>)
					                    			: ('')
					                    	}
					                    </>
				        			</button>
					        		<button type='button' onClick={() => handleBreakTime('min30')} disabled={btnbreak30m}>
					        			30<br />minutes 
					                    <>
					                    	{
					                    		(click30minbreak)
					                    			? (<i className='fa fa-spinner fa-spin'></i>)
					                    			: ('')
					                    	}
					                    </>
				        			</button>
					        		<button type='button' onClick={() => handleBreakTime('hr1')} disabled={btnbreak1h}>
					        			1<br />hour 
					                    <>
					                    	{
					                    		(click1hrbreak)
					                    			? (<i className='fa fa-spinner fa-spin'></i>)
					                    			: ('')
					                    	}
					                    </>
				        			</button>
					        	</div>
	        				</div>)
            			: ('')
            	}
            </>
        </div>
	);
}

export default Tms;
