import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/axios';

import './sites.css';
import Loader from '../../components/loader/Loader';
import Site from '../../components/site/Site';
import { addSites } from '../../redux/slices/DataSlice';

const Sites = () => {
    const isMounted = useRef(false);

    const [loading, setLoading] = useState(true);

    const accessToken = sessionStorage.getItem('accessToken');

    const dispatch = useDispatch();
    const { sites } = useSelector((state) => state.data)

    useEffect(() => {
        isMounted.current = true;

        const fetchSites = async () => {
            const response = await axios.get('getusersitelist', 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken 
                    }
                }
            ).catch((err) => {
                console.log('Error:', err)
            });
            
            if(isMounted.current) dispatch(addSites(response.data.result));
            setLoading(false);
        }

        fetchSites();

        return () => { isMounted.current = false };

    }, [accessToken, dispatch]);
    
    const renderSiteList = [];
    sites.forEach((data, i) => {
        if(data.skey !== 'pftv9') {
            renderSiteList.push(<Site key={i} logoURL={data.logo} title={data.site} role={data.role} link={data.link} />);
        }
    });

    return (
        <div className='rty__dashboard-content_body-sites'>
            <div className='rty__dashboard-content_body-sites_header'>
                <h2>My Apps:</h2>
            </div>

            <div className='rty__dashboard-content_body-sites_content'>

                <>
                    {
                        loading 
                            ? <Loader />
                            : Object.keys(sites).length === 0 
                                ? 'No apps found.' 
                                : renderSiteList
                    }
                </>

            </div>
        </div>
    )
}

export default Sites;
