import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import axios from '../../api/axioscamp';
import axiosaim from '../../api/axios';
import Loader from '../../components/loader/Loader';
import './helpdesk.css';
import { addHelpdesk, addSites } from '../../redux/slices/DataSlice';

const Helpdesk = () => {
    const isMounted = useRef(false);

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const { sites, helpdesk } = useSelector((state) => state.data);

    const accessToken = sessionStorage.getItem('accessToken');
    const name = sessionStorage.getItem('name');
    const uname = sessionStorage.getItem('uname');
    const email = sessionStorage.getItem('email');

    const [itpdhelpdesk, setITPHelpdesk] = useState(true);

    const formHelpdeskErrorRef = useRef();
    const formHelpdeskSuccessRef = useRef();

    const [priority, setPriority] = useState('');
    const [priorityP1Display, setPriorityP1Display] = useState(false);
    const [priorityP2Display, setPriorityP2Display] = useState(false);
    const [priorityP3Display, setPriorityP3Display] = useState(false);
    const [application, setApplication] = useState('');
    const [incidentDate, setIncidentDate] = useState('');
    const [description, setDescription] = useState('');
    const [expectedBehavior, setExpectedBehavior] = useState('');
    const [stepsToReplicate, setStepsToReplicate] = useState('');
    const [attachment, setAttachment] = useState('');

    const [formSubmit, setFormSubmit] = useState(false);
    const [formHelpdeskError, setFormHelpdeskError] = useState(false);
    const [formHelpdeskSuccess, setFormHelpdeskSuccess] = useState(false);
    const [formHelpdeskErrorMsg, setFormHelpdeskErrorMsg] = useState('');
    const [formHelpdeskSuccessMsg, setFormHelpdeskSuccessMsg] = useState('');


    const htmlEntities = (html) => {
        if(typeof(html) !== 'undefined') return html.replace(/&amp;/gi, '&');
    }


    const fetchHelpdeskDatatable = useCallback(async () => {
        
        const helpdesk = await axios.post('helpdesk/getissues',
            JSON.stringify({uname}), 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Aim-Token': accessToken
                }
            }
        );
        
        if (helpdesk.data.status === 'success') dispatch(addHelpdesk(helpdesk.data.data));
        setLoading(false);

    }, [uname, accessToken, dispatch]);


    const fetchSites = useCallback(async () => {
        const response = await axiosaim.get('getusersitelist', 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'X-Aim-Token': accessToken 
                }
            }
        ).catch((err) => {
            console.log('Error:', err)
        });
        
        if(response.data.status === 'success') dispatch(addSites(response.data.result));
        

    }, [accessToken, dispatch]);

    const checkITPDHelpdeskAccess = useCallback(async () => {
            
        try {
            const checkHelpdeskAccess = await axiosaim.post('checksiteaccess', 
                JSON.stringify({
                    'sitekey': process.env.REACT_APP_ITPD_HELPDESK_TOKEN
                }), 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Aim-Token': accessToken
                    }
                }
            );
            
            if (isMounted.current && checkHelpdeskAccess.data.status === 'success') setITPHelpdesk(true);

        } catch (err) {
            
            setITPHelpdesk(false);

        }

    }, [accessToken]);


    useEffect(() => {
        isMounted.current = true;

        fetchHelpdeskDatatable();
        fetchSites();
        checkITPDHelpdeskAccess();

        return () => { isMounted.current = false };
        
    }, [fetchHelpdeskDatatable, fetchSites, checkITPDHelpdeskAccess]);


    useEffect(() => {
        setFormHelpdeskError(false);
        setFormHelpdeskSuccess(false);
        setFormHelpdeskErrorMsg('');
        setFormHelpdeskSuccessMsg('');
    }, [priority, application, incidentDate, description, attachment]);


    const handlePriorityDescription = (val) => {
        setPriority(val);

        if (val === 'P1') {

            setPriorityP1Display(true);
            setPriorityP2Display(false);
            setPriorityP3Display(false);

        } else if (val === 'P2') {

            setPriorityP1Display(false);
            setPriorityP2Display(true);
            setPriorityP3Display(false);

        } else if (val === 'P3') {

            setPriorityP1Display(false);
            setPriorityP2Display(false);
            setPriorityP3Display(true);

        } else {

            setPriorityP1Display(false);
            setPriorityP2Display(false);
            setPriorityP3Display(false);

        }
    }


    const hanldeITPDHelpdeskSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append('name', name);
        formData.append('uname', uname);
        formData.append('email', email);
        setFormSubmit(true);

        try {

            const sendhelpdesk = await axios.post('helpdesk/sendissue',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Aim-Token': accessToken
                    }
                }
            );

            if (sendhelpdesk.data.status === 'success') {
                setFormHelpdeskSuccessMsg(sendhelpdesk?.data.msg);
                setFormHelpdeskSuccess(true);
                formHelpdeskSuccessRef.current.focus();

                fetchHelpdeskDatatable();

                setTimeout(() => {
                    setPriority('');
                    setApplication('');
                    setIncidentDate('');
                    setDescription('');
                    setExpectedBehavior('');
                    setStepsToReplicate('');
                    setAttachment('');
                    setFormSubmit(false);
                    setFormHelpdeskSuccess(false);
                    setFormHelpdeskSuccessMsg('');
                }, 2500);
            }

        } catch (err) {
            if (!err?.response) {
                setFormHelpdeskErrorMsg('No server response');
            } else {
                setFormHelpdeskErrorMsg(err.response?.data.msg);
            }

            formHelpdeskErrorRef.current.focus();
            setFormHelpdeskError(true);
            setFormSubmit(false);
        }
    }

    const columns = useMemo(() => [
        {
            Header: 'Tickets',
            columns: [
                {
                    Header: 'Ticket',
                    accessor: 'ticket'
                },
                {
                    Header: 'Subject',
                    accessor: 'subject'
                },
                {
                    Header: 'Status',
                    accessor: 'status'
                },
                {
                    Header: 'Incident Date',
                    accessor: 'incidentdate'
                },
            ]
        }
    ], []);

    function Table ({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns, data })

        return (
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                return (<th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>)
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, i) => {
                                    let val = cell.render('Cell');
                                    if (cell.column.id === 'status') {
                                        if (cell.value === "1") {
                                            val = <span className="badge badge-secondary">Waiting for Support</span>
                                        } else if (cell.value === "2") {
                                            val = <span className="badge badge-info">Customer Replied</span>
                                        } else if (cell.value === "3") {
                                            val = <span className='badge badge-primary'>In Progress</span>
                                        } else if (cell.value === "4") {
                                            val = <span className='badge badge-info'>On hold</span>
                                        } else if (cell.value === "5") {
                                            val = <span className='badge badge-danger'>Canceled</span>
                                        } else if (cell.value === "6") {
                                            val =  <span className='badge badge-success'>Resolved</span>
                                        } else if (cell.value === "7") {
                                            val = <span className='badge badge-warning'>Merged</span>
                                        } else if (cell.value === "8") {
                                            val = <span className='badge badge-primary'>For Verification</span>
                                        }
                                    }

                                    return (<td {...cell.getCellProps} key={i}>{val}</td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <>
            {
                (itpdhelpdesk) 
                    ? (<div className='rty__dashboard-content_body-helpdesk'>
                            <div className='rty__dashboard-content_body-helpdesk_header'>
                                <h2>ITPD Helpdesk</h2>
                                <Link to='/cVXsR/dashboard'>
                                    <button typeof='button'>
                                        <i className='fa fa-times'></i>
                                    </button>
                                </Link>
                            </div>
                
                            <div className='rty__dashboard-content_body-helpdesk_body'>
                
                                <div className='rty__dashboard-content_body-helpdesk_body-table_holder'>
                                    <>{
                                        loading
                                            ? <Loader />
                                            : Object.keys(helpdesk).length === 0 
                                                ? (<table>
                                                        <thead>
                                                            <tr>
                                                                <th>Ticket</th>
                                                                <th>Subject</th>
                                                                <th>Status</th>
                                                                <th>Incident Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={4}>No data available.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>) 
                                                : <Table columns={columns} data={helpdesk} />
                                    }</>
                                </div>
                                
                                
                                <form encType='multipart/form-data' onSubmit={hanldeITPDHelpdeskSubmit}>
                
                                    <p ref={formHelpdeskErrorRef} className={formHelpdeskError ? 'rty__helpdesk-form_error show' : 'rty__helpdesk-form_error'}>
                                        <i className='fa fa-exclamation-triangle'></i>
                                        <span>{formHelpdeskErrorMsg}</span>
                                    </p>
                
                                    <p ref={formHelpdeskSuccessRef} className={formHelpdeskSuccess ? 'rty__helpdesk-form_success show' : 'rty__helpdesk-form_success'}>
                                        <i className='fa fa-check-circle'></i>
                                        <span>{formHelpdeskSuccessMsg}</span>
                                    </p>
                
                                    <div>
                                        <label>Priority</label>
                                        <select 
                                            name='priority' 
                                            onChange={(e) => handlePriorityDescription(e.target.value)}
                                            value={priority}
                                            disabled={formSubmit} 
                                            required
                                        >
                                            <option value=''></option>
                                            <option value='P1'>P1</option>
                                            <option value='P2'>P2</option>
                                            <option value='P3'>P3</option>
                                        </select>

                                        {(priorityP1Display) ? (<p className='note'><b><i className='fa fa-exclamation-triangle'></i>  BLOCKER / CRITICAL ERRORS</b>. Application is unavailable which causes stoppage in business operations.</p>) : ('')}

                                        {(priorityP2Display) ? (<p className='note'><b><i className='fa fa-exclamation-circle'></i>  MEDIUM ERRORS</b>. Application is unavailable but business operations continues.</p>) : ('')}

                                        {(priorityP3Display) ? (<p className='note'><b><i className='fa fa-exclamation'></i>  MINOR ISSUES</b>. Issues that are not critical while the application is still available.</p>) : ('')}
                                    </div>
                                    <div>
                                        <label>Application</label>
                                        <select 
                                            name='app' 
                                            onChange={(e) => setApplication(e.target.value)} 
                                            value={application}
                                            disabled={formSubmit} 
                                            required
                                        >
                                            <option value=''></option>

                                            <> {
                                                sites && sites.map((site, index) => (
                                                    (site.skey !== 'pftv9') 
                                                        ? <option value={htmlEntities(site.site)} key={index}>{htmlEntities(site.site)}</option>
                                                        : null
                                                ))
                                            } </>
                                            
                                            <option value='Access Identity Management'>Access Identity Management</option>
                                            <option value='Live Odds'>Live Odds</option>
                                            <option value='Prematch Odds'>Prematch Odds</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Incident Date</label>
                                        <input 
                                            type='datetime-local' 
                                            name='incident' 
                                            onChange={(e) => setIncidentDate(e.target.value)} 
                                            value={incidentDate}
                                            disabled={formSubmit} 
                                            required 
                                        />
                                    </div>
                                    <div>
                                        <label>Issue Description</label>
                                        <textarea 
                                            name='description' 
                                            rows='3' 
                                            cols='5' 
                                            onChange={(e) => setDescription(e.target.value)}
                                            value={description}
                                            disabled={formSubmit} 
                                            required 
                                        />
                                    </div>
                                    <div>
                                        <label>Expected Behavior</label>
                                        <textarea 
                                            name='expected-behavior' 
                                            rows='3' 
                                            cols='5' 
                                            onChange={(e) => setExpectedBehavior(e.target.value)}
                                            value={expectedBehavior}
                                            disabled={formSubmit} 
                                            required 
                                        />
                                    </div>
                                    <div>
                                        <label>Steps to Replicate</label>
                                        <textarea 
                                            name='steps-to-replicate' 
                                            rows='3' 
                                            cols='5' 
                                            onChange={(e) => setStepsToReplicate(e.target.value)}
                                            value={stepsToReplicate}
                                            disabled={formSubmit} 
                                            required 
                                        />
                                    </div>
                                    <div>
                                        <label>Attachment</label>
                                        <input 
                                            type='file' 
                                            name='attachment[]' 
                                            accept='.jpg, .jpeg, .png' 
                                            onChange={(e) => setAttachment(e.target.value)}
                                            value={attachment}
                                            disabled={formSubmit} 
                                            multiple 
                                            required 
                                        />
                                    </div>
                                    <div className='rty__dashboard-content_body-helpdesk_form-button_holder'>
                                        <button type='submit' disabled={formSubmit}>
                                            SUBMIT 
                                            <>{
                                                formSubmit
                                                    ? (<i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>)
                                                    : ('')
                                            }</>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>)
                    : (<div className="rty__dashboard-page_error">
                            <p>
                                <i className='fa fa-exclamation-triangle'></i>
                                <span>You are not allowed to access this page.</span>
                            </p>
                        </div>)
            }
        </>
    )
}

export default Helpdesk;
