import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.css';

import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import { Login, ForgotPassword, ChangePassword, VerifyAccount, Dashboard, Sites, Helpdesk, Settings } from './containers';

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                {/* public routes */}
                <Route exact path='/' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/account/:token/change-password' element={<ChangePassword />} />
                <Route path='/account/:token/verify' element={<VerifyAccount />} />
                <Route path='*'>404 Not Found!</Route>

                {/* protected routes */}
                <Route element={<RequireAuth />}>
                        <Route path='cVXsR' element={<Dashboard />}>
                            <Route path='dashboard' element={<Sites />} />
                            <Route path='settings' element={<Settings />} />
                            <Route path='itpd-helpdesk' element={<Helpdesk />} />
                        </Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default App;
