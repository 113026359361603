import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';

import './login.css';
import logo from '../../assets/msw-logo-white.png'

const Login = () => {
    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();

    const [uname, setUname] = useState('');
    const [pword, setPword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);

    /*const getIPaddress = async () => {
        const res = await axios.get('//geolocation-db.com/json/')
        console.log(res.data);
    }*/

    useEffect(() => {
        userRef.current.focus();
        //getIPaddress();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [uname, pword])

    let isMounted = true;
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmit(true);

        try {
            const response = await axios.post('auth', 
                JSON.stringify({uname, pword}),
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN 
                    }
                }
            );
            
            if(response && isMounted) {
                const accessToken = response?.data.token;
                const name = response?.data.name;
                // const pwdexp = response?.data.pwdexp;
                const theme = response?.data.theme;

                const details = await axios.post('getuserdetails', 
                    JSON.stringify({}), 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Aim-Token': accessToken
                        }
                    }
                );
                
                const email = (details?.data.email) ? details?.data.email : '';
                const emp_id = (details?.data.employee_id) ? details?.data.employee_id : '';
                const img = (details?.data.image) ? details?.data.image : '';
                const dept = (details?.data.department) ? details?.data.department : '';
                const deptcode = (details?.data.department_slug) ? details?.data.department_slug : '';
                const outlet = (details?.data.outlet_name) ? details?.data.outlet_name : '';
                const outletcode = (details?.data.outlet_code) ? details?.data.outlet_code : '';

                sessionStorage.setItem('uname', uname);
                sessionStorage.setItem('name', name);
                sessionStorage.setItem('email', email);
                sessionStorage.setItem('emp_id', emp_id);
                sessionStorage.setItem('img', img);
                sessionStorage.setItem('department', dept);
                sessionStorage.setItem('department_code', deptcode);
                sessionStorage.setItem('outlet', outlet);
                sessionStorage.setItem('outlet_code', outletcode);
                // sessionStorage.setItem('pwdexp', pwdexp);
                sessionStorage.setItem('theme', theme);
                sessionStorage.setItem('accessToken', accessToken);
                    

                navigate('/cVXsR/dashboard');
                setUname('');
                setPword('');
                setFormSubmit(false);
            }
        } catch(err) {
            console.log(err)
            if(!err?.response) {
                setErrMsg('No server response!');
            } else {
                setErrMsg(err.response?.data.msg);
            }
            errRef.current.focus();
            setFormSubmit(false);
        }

        return () => {
            isMounted = false;
        }
    }

    return (
        <div className='rty__login'>
            <div className='rty__login-container'>
                <form id='frmLogin' onSubmit={handleSubmit}>

                    <div ref={errRef} className={errMsg ? "rty__login-message_error show" : "rty__login-message_error hide"} aria-live='assertive'>
                        <p><i className="fa fa-exclamation-triangle"></i> <span>{errMsg}</span></p>
                    </div>

                    <div className='rty__login-form_header'>
                        {/* content before waves */}
                        <div className='rty__login-form_header-logo-container'>
                            <div className='rty__login-form_header-logo'>
                                <img src={logo} alt='logo' />
                            </div>
                        </div>

                        {/* waves container */}
                        <div className='rty__login-form_header-waves'>
                            <svg className='waves' xmlns='//www.w3.org/2000/svg' xmlnsXlink='//www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                <defs>
                                    <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                </defs>
                                <g className='parallax'>
                                    <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                    <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div className='rty__login-form_body'>

                        <input 
                            type='text' 
                            id='uname' 
                            placeholder='Username' 
                            autoComplete='off' 
                            ref={userRef} 
                            onChange={(e) => setUname(e.target.value)} 
                            value={uname} 
                            required
                            disabled={formSubmit} 
                        />
                        <input 
                            type='password' 
                            id='pword' 
                            placeholder='Password' 
                            onChange={(e) => setPword(e.target.value)} 
                            value={pword}  
                            required 
                            disabled={formSubmit}
                        />
                        <button disabled={formSubmit}>
                            LOGIN 
                            <>
                                {
                                    formSubmit ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                }
                            </>
                        </button>
                        <p>
                            <Link to='forgot-password'>Forgot Password?</Link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
