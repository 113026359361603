import { Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
    const accessToken = sessionStorage.getItem('accessToken');

    return (
        accessToken 
            ? <Outlet /> 
            : <Navigate to='/' />
    );
}

export default RequireAuth;
