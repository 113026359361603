import React from 'react';

import './site.css';

const Site = ({ logoURL, title, role, link }) => {
    const accessToken = sessionStorage.getItem('accessToken');

    const htmlEntities = (html) => {
        return html.replace(/&amp;/gi, '&');
    }

    return (
        <div className='rty__dashboard-content_body-site'>
            <div className='rty__dashboard-content_body-site_logo'>
                <img src={logoURL} alt='logo' />
            </div>
            <div className='rty__dashboard-content_body-site_details'>
                <h4>{htmlEntities(title)}</h4>
                <p>{role}</p>
            </div>
            <div className='rty__dashboard-content_body-site_footer'>
                <a href={`${link}?auth=${accessToken}`} target="_blank" rel="noreferrer">
                    <button type='button'>
                        <i className='fa fa-external-link-square-alt'></i>
                        Visit
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Site;
