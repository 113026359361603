import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';

import './verify-account.css';
import logo from '../../assets/msw-logo.png';

const VerifyAccount = () => {
    const { token } = useParams();
    
    const [pageError, setPageError] = useState(false);
    const [pageErrMsg, setPageErrMsg] = useState();
    const [showPwd, setShowPwd] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    
    const formTempPwdErrorRef = useRef();
    const [tempPwdErrorMsg, setTempPwdErrorMsg] = useState('');
    const [formTempPwdError, setFormTempPwdError] = useState(false);
    const [correctTempPwd, setCorrectTempPwd] = useState(false);
    const [formSubmitTempPwd, setFormSubmitTempPwd] = useState(false);
    const [tempPwd, setTempPwd] = useState('');

    const pwdErrorRef = useRef();
    const formAccountUpdateErrorRef = useRef();
    const [pwdError, setPwdError] = useState(false);
    const [accountUpdateErrorMsg, setAccountUpdateErrorMsg] = useState('');
    const [formAccountUpdateError, setFormAccountUpdateError] = useState(false);
    const [correctAccountUpdate, setCorrectAccountUpdate] = useState(false);
    const [formSubmitAccountUpdate, setFormSubmitAccountUpdate] = useState(false);
    
    const [img, setImg] = useState('');
    const [avatar, setAvatar] = useState('');
    const [selectedAvatar, setSelectedAvatar] = useState(null);

    const [delegation, setDelegation] = useState('');
    const [uid, setUID] = useState('');
    const [uname, setUname] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [empID, setEmpID] = useState('');
    const [gelID, setGelID] = useState('');
    const [department, setDepartment] = useState('');
    const [position, setPosition] = useState('');
    const [operator, setOperator] = useState('');
    const [outlet, setOutlet] = useState('');

    const [pword, setPassword] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [bdate, setBdate] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [securityQuestion, setSecurityQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [address, setAddress] = useState('');

    const handleNullData = (val) => {
        return (val === null) ? '-' : val;
    }

    useEffect(() => {
        setPageError(false);
        setPageErrMsg('');
        setFormSubmitTempPwd(false);
        setFormSubmitAccountUpdate(false);
    }, []);

    useEffect(() => {
        setTempPwdErrorMsg('');
        setFormTempPwdError(false);
        setCorrectTempPwd(false);
    }, [tempPwd]);

    useEffect(() => {
        setAccountUpdateErrorMsg('');
        setFormAccountUpdateError(false);
        setCorrectAccountUpdate(false);
    }, [contactNumber, bdate, civilStatus, gender, nationality, securityQuestion, answer, address]);

    useEffect(() => {
        (selectedAvatar)
            ? setAvatar(URL.createObjectURL(selectedAvatar))
            : setAvatar((img !== '') ? img : logo);
    }, [selectedAvatar, img]);

    const handleShowPwd = (e) => {
        e.preventDefault();
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    const handlePassword = (e) => {
        if (e.target.value.length < 6 || e.target.value.length > 10 || !/[A-Z]/.test(e.target.value) || !/[a-z]/.test(e.target.value) || !/[0-9]/.test(e.target.value)) {
            setPwdError(true);
            pwdErrorRef.current.focus();
        } else {
            setPwdError(false);
        }
        
        setPassword(e.target.value);
    }

    const handleContactNumber = (e) => {
        if (e.target.value.length > 11) {
            e.preventDefault()
        } else{
            setContactNumber(e.target.value);
        }
    }

    const handleShowAnswer = (e) => {
        e.preventDefault();
        if (showAnswer) {
            setShowAnswer(false);
        } else {
            setShowAnswer(true);
        }
    }

    const handleTempPwdChecking = async (e) => {
        e.preventDefault();
        setFormSubmitTempPwd(true);

        try {

            const response = await axios.post('verifytemppwd',
                JSON.stringify({token, tpwd: tempPwd}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN
                    }
                }    
            );
            
            if(response.data.status === 'success') {
                setCorrectTempPwd(true);

                setDelegation(handleNullData(response?.data.details.delegation));
                setImg('');
                setUID(handleNullData(response?.data.details.uid));
                setUname(handleNullData(response?.data.details.username));
                setName(`${handleNullData(response?.data.details.firstname)} ${handleNullData(response?.data.details.lastname)}`);
                setEmail(handleNullData(response?.data.details.emailaddress));
                setEmpID(handleNullData(response?.data.details.employeeid));
                setGelID(handleNullData(response?.data.details.gelid));
                setDepartment(handleNullData(response?.data.details.department));
                setPosition(handleNullData(response?.data.details.position));
                setOperator(handleNullData(response?.data.details.operator));
                setOutlet(handleNullData(response?.data.details.outlet));
                
                setContactNumber(handleNullData(response?.data.details.contactnumber));
                // setBdate(handleNullData(response?.data.details.birthdate));
                setCivilStatus(handleNullData(response?.data.details.civilstatus));
                setGender(handleNullData(response?.data.details.gender));
                setNationality(handleNullData(response?.data.details.nationality));
                setAddress(handleNullData(response?.data.details.address));

                setCorrectAccountUpdate(response?.data.details.verified);
            }
            setFormSubmitTempPwd(false);

        } catch(err) {
            if(!err?.response) {
                setTempPwdErrorMsg('No server response!');
            } else {
                setTempPwdErrorMsg(err.response?.data.msg);
            }
            formTempPwdErrorRef.current.focus();
            setFormTempPwdError(true);
            setFormSubmitTempPwd(false);
        }
    }

    const handleAccountUpdateSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append('avatar', selectedAvatar);
        formData.append('uname', uname);
        formData.append('uid', uid);
        setFormSubmitAccountUpdate(true);

        try {

            const response = await axios.post('verifyaccount',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN 
                    }
                }
            );

            if(response.data.status === 'success') setCorrectAccountUpdate(true);
            setFormSubmitAccountUpdate(false);

        } catch(err) {
            if(!err?.response) {
                setAccountUpdateErrorMsg('No server response!');
            } else {
                setAccountUpdateErrorMsg(err.response?.data.msg);
            }
            formAccountUpdateErrorRef.current.focus();
            setFormAccountUpdateError(true);
            setFormSubmitAccountUpdate(false);
        }
    }

    return (
        <>
            { pageError  
                ? ( <Error msg={pageErrMsg} /> )
                : correctAccountUpdate
                    ? ( <Success msg='Account successfully verified.' /> )
                    : (
                        <div className='rty__verify_account'>
                            <div className='rty__verify_account-logo'>
                                <img src={logo} alt='logo' />
                                <div className='rty_verify_account-logo_title'>
                                    <p>Centralized <span>Access</span></p>
                                    <p>Management <span>Program</span></p>
                                </div>
                            </div>

                            <div className='rty__verify_account-container'>
                            <>
                                { !correctTempPwd ? (
                                    <div className='rty__verify_account-checker'>
                                        <form id='frmAccountVerification' onSubmit={handleTempPwdChecking}>
                                            <input 
                                                type='password' 
                                                id='pword' 
                                                placeholder='Temporary Password' 
                                                onChange={(e) => setTempPwd(e.target.value)} 
                                                value={tempPwd}
                                                disabled={formSubmitTempPwd ? true : false} 
                                                required 
                                            />
                                            <p ref={formTempPwdErrorRef} className={formTempPwdError ? 'rty__verify_account-checker-error show' : 'rty__verify_account-checker-error'}>{tempPwdErrorMsg}</p>
                                            <button disabled={formSubmitTempPwd ? true : false}>
                                                SUBMIT 
                                                <>
                                                    {
                                                        formSubmitTempPwd ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                                    }
                                                </>
                                            </button>
                                        </form>
                                    </div>

                                    ) : (

                                    <div className='rty__verfiy_account-update'>
                                        <p ref={formAccountUpdateErrorRef} className={formAccountUpdateError ? 'rty__verify_account-update-error show' : 'rty__verify_account-update-error'}>
                                            <i className='fa fa-exclamation-triangle'></i>
                                            <span>{accountUpdateErrorMsg}</span>
                                        </p>

                                        <form id='frmAccountDetailsUpdate' encType='multipart/form-data' onSubmit={handleAccountUpdateSubmit}>

                                            <div className='rty__verify_account-update_details'>
                                                <div className='rty__verify_account-update_details_container'>
                                                    <div className='rty__verify_account-update_avatar'>
                                                        <img src={avatar} alt='avatar' />
                                                        <label>
                                                            <input 
                                                                type='file'  
                                                                accept='.jpg, .jpeg, .png' 
                                                                onChange={(e) => setSelectedAvatar(e.target.files[0])}  
                                                                disabled={formSubmitAccountUpdate ? true : false} 
                                                            />
                                                            <i className='fa fa-upload'></i>
                                                        </label>
                                                    </div>

                                                    <div className='rty__verify_account-update_details-content'>
                                                        <div>
                                                            <label>Username:</label>
                                                            <h4>{uname}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Name:</label>
                                                            <h4>{name}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Email:</label>
                                                            <h4>{email}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Employee ID:</label>
                                                            <h4>{empID}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Gel ID:</label>
                                                            <h4>{gelID}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Department:</label>
                                                            <h4>{department}</h4>
                                                        </div>
                                                        <div>
                                                            <label>Position:</label>
                                                            <h4>{position}</h4>
                                                        </div>

                                                        <>
                                                            {
                                                                (delegation === 'Outlet' || delegation === 'Operator')
                                                                    ? (
                                                                        <div>
                                                                            <label>Operator:</label>
                                                                            <h4>{operator}</h4>
                                                                        </div>
                                                                    ) : ('')
                                                            }
                                                        </>

                                                        <>
                                                            {
                                                                (delegation === 'Outlet')
                                                                    ? (
                                                                        <div>
                                                                            <label>Outlet:</label>
                                                                            <h4>{outlet}</h4>
                                                                        </div>
                                                                    ) : ('')
                                                            }
                                                        </>
                                                        
                                                        
                                                    </div>
                                                </div>


                                                {/* input fields */}
                                                <div className='rty__verify_account-update_input_container'>
                                                    <div>
                                                        <label>Password:</label>
                                                        <div className='rty__verify_account-update_input-with_button'>
                                                            <input 
                                                                type={(showPwd) ? 'text' : 'password'} 
                                                                name='pword' 
                                                                autoComplete='off'
                                                                onChange={handlePassword}
                                                                value={pword}
                                                                disabled={formSubmitAccountUpdate ? true : false} 
                                                                required 
                                                            />
                                                            <button 
                                                                className='rty__verify_account-update_input-change-view' 
                                                                type='button' 
                                                                onClick={handleShowPwd}
                                                            >
                                                                <i className={(showPwd) ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                            </button>
                                                        </div>
                                                        <p ref={pwdErrorRef} className={pwdError ? 'rty__verify_account-form_err-msg show' : 'rty__verify_account-form_err-msg'}>Password should be between 6 to 10 characters and a combination of an uppercase, lowercase and a number.</p>
                                                    </div>

                                                    <div>
                                                        <label>Contact Number:</label>
                                                        <input 
                                                            type="number" 
                                                            name="contactnumber" 
                                                            autoComplete="off" 
                                                            onChange={handleContactNumber}
                                                            value={contactNumber}
                                                            maxLength='11' 
                                                            minLength='11'
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required
                                                        />
                                                    </div>

                                                    <div>
                                                        <label>Birthdate:</label>
                                                        <input 
                                                            type="date" 
                                                            name="bdate" 
                                                            autoComplete="off"
                                                            onChange={(e) => setBdate(e.target.value)} 
                                                            value={bdate} 
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required
                                                        />
                                                    </div>

                                                    <div>
                                                        <label>Civil Status</label>
                                                        <select 
                                                            name='civilstatus'
                                                            onChange={(e) => setCivilStatus(e.target.value)} 
                                                            value={civilStatus}
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required
                                                        >
                                                            <option value=''></option>
                                                            <option value='Single'>Single</option>
                                                            <option value='Married'>Married</option>
                                                            <option value='Widowed'>Widowed</option>
                                                            <option value='Separated'>Separated</option>
                                                            <option value='Divorced'>Divorced</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label>Gender:</label>
                                                        <select 
                                                            name="gender" 
                                                            onChange={(e) => setGender(e.target.value)}
                                                            value={gender}
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required 
                                                        >
                                                            <option value=""></option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label>Nationality:</label>
                                                        <input 
                                                            type="text" 
                                                            name="nationality" 
                                                            autoComplete="off" 
                                                            onChange={(e) => setNationality(e.target.value)}
                                                            value={nationality} 
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required 
                                                        />
                                                    </div>
                                                    

                                                    <div>
                                                        <label>Security Question:</label>
                                                        <select 
                                                            name="securityquestion" 
                                                            onChange={(e) => setSecurityQuestion(e.target.value)}
                                                            value={securityQuestion} 
                                                            disabled={formSubmitAccountUpdate ? true : false} 
                                                            required
                                                        >
                                                            <option value=""></option>
                                                            <option value="What is the food you least liked as a child?">What is the food you least liked as a child?</option>
                                                            <option value="What is the name of your first stuffed animal?">What is the name of your first stuffed animal?</option>
                                                            <option value="What did you earn your first medal or award for?">What did you earn your first medal or award for?</option>
                                                            <option value="What is your favorite security question?">What is your favorite security question?</option>
                                                            <option value="What is the toy/stuffed animal you liked the most as a kid?">What is the toy/stuffed animal you liked the most as a kid?</option>
                                                            <option value="What was the first computer game you played?">What was the first computer game you played?</option>
                                                            <option value="What is your favorite movie quote?">What is your favorite movie quote?</option>
                                                            <option value="What was the mascot of the first sports team you played on?">What was the mascot of the first sports team you played on?</option>
                                                            <option value="What music album or song did you first purchase?">What music album or song did you first purchase?</option>
                                                            <option value="What is your favorite piece of art?">What is your favorite piece of art?</option>
                                                            <option value="What was your grandmother`s favorite dessert?">What was your grandmother's favorite dessert?</option>
                                                            <option value="What was the first thing you learned to cook?">What was the first thing you learned to cook?</option>
                                                            <option value="What was your dream job as a child?">What was your dream job as a child?</option>
                                                            <option value="Where did you meet your spouse/significant other?">Where did you meet your spouse/significant other?</option>
                                                            <option value="Where did you go for your favorite vacation?">Where did you go for your favorite vacation?</option>
                                                            <option value="Where were you on New Year`s Eve in the year 2000?">Where were you on New Year's Eve in the year 2000?</option>
                                                            <option value="Who is your favorite speaker/orator?">Who is your favorite speaker/orator?</option>
                                                            <option value="Who is your favorite book/movie character?">Who is your favorite book/movie character?</option>
                                                            <option value="Who is your favorite sports player?">Who is your favorite sports player?</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label>Answer:</label>
                                                        <div className='rty__verify_account-update_input-with_button'>
                                                            <input 
                                                                type={(showAnswer) ? 'text' : 'password'} 
                                                                name="securityanswer" 
                                                                autoComplete="off" 
                                                                onChange={(e) => setAnswer(e.target.value)}
                                                                value={answer}
                                                                disabled={formSubmitAccountUpdate ? true : false}
                                                                required 
                                                            />
                                                            <button 
                                                                className='rty__verify_account-update_input-change-view' 
                                                                type='button' 
                                                                onClick={handleShowAnswer}
                                                            >
                                                                <i className={(showAnswer) ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label>Address:</label>
                                                        <textarea 
                                                            name="address" 
                                                            rows="3" 
                                                            cols="5" 
                                                            onChange={(e) => setAddress(e.target.value)} 
                                                            value={address} 
                                                            disabled={formSubmitAccountUpdate ? true : false}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='rty__verify_account-update_button_container'>
                                                <button 
                                                    disabled={formSubmitAccountUpdate || pwdError ? true : false}
                                                >
                                                    SUBMIT 
                                                    <>
                                                        {
                                                            formSubmitAccountUpdate ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                                        }
                                                    </>
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    )
                                }
                            </>
                            </div>
                            
                            <div className='rty__verify_account-footer'>
                                <p>&copy; Megasportsworld 2020.</p>
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default VerifyAccount;
