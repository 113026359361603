import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from '../../api/axios';
import Error from '../../components/error/Error';

import './change-password.css';
import logo from '../../assets/msw-logo-white.png';

const ChangePassword = () => {
    const navigate = useNavigate();

    const errRefSA = useRef();
    const errRefCP = useRef();
    const sucRefCP = useRef();
    const { token } = useParams();

    const pwdErrorRef = useRef();
    const [pwdError, setPwdError] = useState(false);

    const cdt = new Date();
    const currentDateTime = moment(cdt).format('YYYY-MM-DD HH:mm:ss');
    const [pageError, setPageError] = useState(false);
    const [pageErrMsg, setPageErrMsg] = useState('');

    const [tokenExpiration, setTokenExpiration] = useState('');
    const [sQuestion, setSecurityQuestion] = useState('');
    const [uid, setUID] = useState('');

    const [answer, setSecurityAnswer] = useState('');
    const [errMsgSA, setErrMsgSA] = useState('');
    const [formSubmitSA, setFormSubmitSA] = useState(false);

    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [pword, setPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [errMsgCP, setErrMsgCP] = useState('');
    const [sucMsgCP, setSucMsgCP] = useState('');
    const [formSubmitCP, setFormSubmitCP] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    
    
    useEffect(() => {
        const fetchSecurityQuestion = async () => {
            try {

                const response = await axios.get('getpasswordresetdata', {
                    params: {
                        't': token
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN 
                    }
                });
                
                setUID(response?.data.data.userid);
                setSecurityQuestion(response?.data.data.securityquestion);
                setTokenExpiration(response?.data.data.pwdtokenexpiration);

            } catch(err) {
                if(!err?.response) {
                    setPageErrMsg('No server response!');
                } else {
                    setPageErrMsg(err.response?.data.msg);
                }
                setPageError(true);
            }
        }

        fetchSecurityQuestion();
        setPageError(false);
        setPageErrMsg('');
        setTokenExpiration('');
    }, [token]);

    useEffect(() => {
        setErrMsgSA('');
    }, [answer]);

    useEffect(() => {
        setErrMsgCP('');
    }, [pword, confirmPwd]);

    const handleSubmitSA = async (e) => {
        e.preventDefault();
        setFormSubmitSA(true);

        try {

            const response = await axios.post('checksecurityanswer',
                JSON.stringify({uid, answer}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN
                    }
                }
            );
                
            if(response.data.status === 'success') setCorrectAnswer(true);

            setFormSubmitSA(false);

        } catch(err) {
            if(!err?.response) {
                setErrMsgSA('No server response!');
            } else {
                setErrMsgSA(err.response?.data.msg);
            }
            errRefSA.current.focus();
            setFormSubmitSA(false);
        }
    }

    const handleShowPwd = (e) => {
        e.preventDefault();
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    const handlePassword = (e) => {
        if (e.target.value.length < 6 || e.target.value.length > 10 || !/[A-Z]/.test(e.target.value) || !/[a-z]/.test(e.target.value) || !/[0-9]/.test(e.target.value)) {
            setPwdError(true);
            pwdErrorRef.current.focus();
        } else {
            setPwdError(false);
        }
        
        setPwd(e.target.value);
    }

    const handleSubmitCP = async (e) => {
        e.preventDefault();
        setFormSubmitCP(true);
        
        if(pword === confirmPwd) {

            try {
                const response = await axios.post('updatepassword', 
                    JSON.stringify({uid, pword}), 
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN
                        }
                    }
                );

                setSucMsgCP(response.data.msg);
                sucRefCP.current.focus();
                
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            } catch(err) {
                if(!err?.response) {
                    setErrMsgCP('No server response!');
                } else {
                    setErrMsgCP(err.response?.data.msg);
                }
                errRefCP.current.focus();
                setFormSubmitCP(false);
            }

        } else {
            setErrMsgCP('Password not match!');
            errRefCP.current.focus();
            setFormSubmitCP(false);
        }
    }

    return (
        <>
            {
                pageError  
                    ? ( <Error msg={pageErrMsg} /> )
                    : (tokenExpiration !== '' && currentDateTime > tokenExpiration) 
                        ? ( <Error msg='Password reset token already expired!' /> )
                        : (
                            <div className={tokenExpiration === '' ? 'rty__change-password hide' : 'rty__change-password'}>
                                <div className='rty__change-password-container'>
                                    <div className='rty__change-password-form_wrapper'>

                                        <div ref={errRefSA} className={errMsgSA ? "rty__change-password-message_error show" : "rty__change-password-message_error"} aria-live='assertive'>
                                            <p><i className="fa fa-exclamation-triangle"></i> <span>{errMsgSA}</span></p>
                                        </div>

                                        <div ref={errRefCP} className={errMsgCP ? "rty__change-password-message_error show" : "rty__change-password-message_error"} aria-live='assertive'>
                                            <p><i className="fa fa-exclamation-triangle"></i> <span>{errMsgCP}</span></p>
                                        </div>

                                        <div ref={sucRefCP} className={sucMsgCP ? 'rty__change-password-message_success show' : 'rty__change-password-message_success'} aria-live='assertive'>
                                            <p><i className="fa fa-check-circle"></i> <span>{sucMsgCP}</span></p>
                                        </div>


                                        <div className='rty__change-password-form_header'>
                                            {/* content before waves */}
                                            <div className='rty__change-password-form_header-logo-container'>
                                                <div className='rty__change-password-form_header-logo'>
                                                    <img src={logo} alt='logo' />
                                                </div>
                                            </div>

                                            {/* waves container */}
                                            <div className='rty__change-password-form_header-waves'>
                                                <svg className='waves' xmlns='//www.w3.org/2000/svg' xmlnsXlink='//www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                                    <defs>
                                                        <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                                    </defs>
                                                    <g className='parallax'>
                                                        <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                                        <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                    <>
                                        { !correctAnswer ? (
                                                <form id='frmCheckSecurityAnswer' onSubmit={handleSubmitSA}>

                                                    <div className='rty__change-password-form_body'>
                                                        <p className='rty__change-password-form_body-question'>{sQuestion}</p>
                                                        <input 
                                                            type='password' 
                                                            id='sanswer' 
                                                            onChange={(e) => setSecurityAnswer(e.target.value)} 
                                                            value={answer} 
                                                            placeholder='Security Answer' 
                                                            autoComplete='off' 
                                                            required 
                                                            disabled={formSubmitSA || (sQuestion === '') ? true : false}
                                                        />
                                                        <button disabled={formSubmitSA || (sQuestion === '') ? true : false}>
                                                            SUBMIT 
                                                            <>
                                                                {
                                                                    formSubmitSA ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                                                }
                                                            </>
                                                        </button>
                                                    </div>
                                                </form>

                                        ) : (

                                                <form id='frmChangePassword' onSubmit={handleSubmitCP}>

                                                    <div className='rty__change-password-form_body'>
                                                        <div>
                                                        <label className='rty__change-password-form_group'>
                                                            <input 
                                                                type={(showPwd) ? 'text' : 'password'} 
                                                                id="pword"
                                                                onChange={handlePassword}
                                                                value={pword}
                                                                placeholder="New Password" 
                                                                autoComplete="off" 
                                                                required 
                                                                disabled={formSubmitCP}
                                                            />
                                                            <button className="rty__change-password-form_change-view" type="button" onClick={handleShowPwd}>
                                                                <i className={(showPwd) ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                            </button>
                                                        </label>
                                                            <p ref={pwdErrorRef} className={pwdError ? 'rty__change-password-form_err-msg show' : 'rty__change-password-form_err-msg'}>Password should be between 6 to 10 characters and a combination of an uppercase, lowercase and a number.</p>
                                                    </div>

                                                    <div>
                                                        <input 
                                                            type="password" 
                                                            id="cpword" 
                                                            onChange={(e) => setConfirmPwd(e.target.value)} 
                                                            value={confirmPwd}
                                                            placeholder="Confirm Password" 
                                                            autoComplete="off" 
                                                            required 
                                                            disabled={formSubmitCP}
                                                        />
                                                    </div>
                                                    <button disabled={formSubmitCP}>
                                                        SUBMIT 
                                                        <>
                                                            {
                                                                formSubmitCP ? ( <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>) : ('') 
                                                            }
                                                        </>
                                                    </button>
                                                    </div>
                                                </form>
                                            )
                                        }
                                    </>
                                    </div>
                                </div>
                            </div>
                        )
                }
            </>
    )
}

export default ChangePassword;
