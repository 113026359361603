import React from 'react';

import './success.css';
import logo from '../../assets/msw-logo.png';

const Success = ({ msg }) => {
    return (
        <div className='rty__success'>
            <p className='rty__success-msg'>
                <i className='fa fa-check-circle'></i>
                <span>{msg}</span>
            </p>
            <div className='rty__success-logo'>
                <img src={logo} alt='logo' />
                <div className='rty__success-logo_title'>
                    <p>Centralized <span>Access</span></p>
                    <p>Management <span>Program</span></p>
                </div>
            </div>
        </div>
    )
}

export default Success;
